import React, { useEffect, useMemo, useState } from "react";
import { Terminal } from "./Terminal";
import Face from "./Face";
import { useTerminal } from "./Terminal/hooks";
import FaceSplash from "./Face/splash";
import WelcomeText from "./WelcomeText";
import { animated, useSpring } from "@react-spring/web";

import { create } from "zustand";

type AnimState =
  | "RENDER_LOADING_TEXT"
  | "RENDER_SPLASH_FACE"
  | "HIDE_LOADING_TEXT"
  | "RENDER_WELCOME_TEXT"
  | "ANIM_OUT_TEXT"
  | "START_RENDER_MAIN";
export interface AnimStore {
  currentState: AnimState;
  setState: (newState: AnimState) => void;
}

const useAnimStore = create<AnimStore>((set) => ({
  currentState: "RENDER_LOADING_TEXT",
  setState: (newState: AnimState) => set({ currentState: newState }),
}));

function App() {
  const state: AnimStore = useAnimStore();

  const { history, pushToHistory, setTerminalRef, resetTerminal } =
    useTerminal();

  useEffect(() => {
    resetTerminal();
  }, []);

  const [showSplash, setShowSplash] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Enter" && showSplash) {
        state.setState("ANIM_OUT_TEXT");
      }
    });
    return () => {
      window.removeEventListener("keydown", () => setShowSplash(false));
    };
  }, []);

  useEffect(() => {
    if (state.currentState === "START_RENDER_MAIN") {
      setShowSplash(false);
    }
  }, [state.currentState]);

  const answerQuestion = async (prompt: string) => {
    // Using Fetch API
    const r = await fetch("https://michael-gorman-backend-112b0d21f89d.herokuapp.com/answer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question: prompt,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        return data.response;
      })
      .catch((err) => {
        return "I'm not sure how to answer that! -- " + err.toString();
      });
    return r;
  };

  const welcomeText = ">>> hello...";

  const springs = useSpring({
    from: { width: 0 },
    to: { width: 1000 },
  });

  return (
    <div
      className="App"
      style={{ width: "100vw", height: "100vh", backgroundColor: "black" }}
    >
      {/* <animated.div
      style={{
        height: 80,
        background: '#ff6d6d',
        borderRadius: 8,
        ...springs,
      }} */}
      {/* <FaceSplash animStore={state} /> */}
      {!showSplash && (
        <Terminal
          ref={setTerminalRef}
          promptLabel={"Ask me anything >>"}
          answerQuestion={answerQuestion}
          animStore={state}
        />
      )}
      {showSplash && (
        <>
          <WelcomeText animStore={state} />
        </>
      )}
    </div>
  );
}

export default App;
