import "./terminal.css";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { TerminalProps, TerminalHistoryItem } from "./types";

export const Terminal = forwardRef(
  (props: TerminalProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { promptLabel = ">" } = props;

    const [history, setHistory] = useState<any[]>([
      {
        prompt: 'What is this?',
        answer: 'I\'m a chatbot that answers everything from the perspective of Michael Gorman. Ask me anything!',
      }
    ]);

    const inputRef = useRef<HTMLInputElement>();
    const [input, setInputValue] = useState<string>("");
    const [inputHistory, setInputHistory] = useState<string[]>([]);
    const [historyCursor, setHistoryCursor] = useState<number | undefined>(
      undefined,
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0);
    const [firstRenderCount, setFirstRenderCount] = useState<number>(0);

    useEffect(() => {
      const interval = setInterval(
        () => setNewCount(new Date().getSeconds()),
        25,
      );

      return () => clearInterval(interval);
    }, []);

    const setNewCount = (c: number) => {
      setCount((c) => c + 1);
      setFirstRenderCount((c) => c + 1);
    };

    /**
     * Focus on the input whenever we render the terminal or click in the terminal
     */
    useEffect(() => {
      inputRef.current?.focus();
    });

    const focusInput = useCallback(() => {
      inputRef.current?.focus();
    }, []);

    /**
     * When user types something, we update the input value
     */
    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
      },
      [],
    );

    /**
     * When user presses enter, we execute the command
     */
    const handleInputKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          setLoading(true);
          setCount(0);
          setInputHistory([...inputHistory, input]);
          const h = [...history, { prompt: input, answer: "Loading..." }];
          setHistory(h);
          props.answerQuestion(input).then((ans: string) => {
            setLoading(false);
            setCount(0);
            const newH = [...h];
            newH[newH.length - 1].answer = ans;
            setHistory(h);
          });
          setInputValue("");
          setHistoryCursor(undefined);
        } else if (e.key === "ArrowUp") {
          var i = historyCursor;
          if (i === undefined) {
            i = history.length;
          }
          if (i > 0) {
            setInputValue(history[i - 1].prompt);
            setHistoryCursor(i - 1);
          }
        } else if (e.key === "ArrowDown") {
          var i = historyCursor;
          if (i === undefined) {
            return;
          }
          if (i < history.length - 1) {
            setInputValue(history[i + 1].prompt);
            setHistoryCursor(i + 1);
          }
        }
      },
      [input],
    );

    return (
      <div className="terminal" ref={ref} onClick={focusInput}>
        <div style={{}}>
          {history.map((line, index) => (
            <>
              <div>
                <span className="terminal__prompt__label">{promptLabel}</span>
                <span className="terminal__prompt__history">{line.prompt}</span>
              </div>
              {index == history.length - 1 && loading && (
                <span className="terminal__prompt__history">
                  {"Hmm ...".substring(0, count)}
                </span>
              )}
              {index == history.length - 1 && !loading && (
                <span className="terminal__prompt__history">
                  {line.answer?.substring(0, count) ?? "Something went wrong!"}
                </span>
              )}
              {index != history.length - 1 && (
                <span className="terminal__prompt__history">{line.answer}</span>
              )}
            </>
          ))}
          {/* {history.map((line, index) => (
        <div className="terminal__line" key={`terminal-line-${index}-${line}`}>
          {line}
        </div>
      ))} */}
          {!loading &&
            (history.length == 0 ||
              history[history.length - 1].answer.length < count) && (
              <div className="terminal__prompt">
                <div className="terminal__prompt__label">
                  {promptLabel.substring(0, firstRenderCount)}
                </div>
                <div className="terminal__prompt__input">
                  <input
                    type="text"
                    value={input}
                    onKeyDown={handleInputKeyDown}
                    onChange={handleInputChange}
                    // @ts-ignore
                    ref={inputRef}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
    );
  },
);
