import React, { useEffect, useMemo, useState } from "react";
import { AnimStore } from "../App";

type Props = {
  animStore: AnimStore;
};

function WelcomeText(props: Props) {
  const [count, setCount] = useState<number>(0);
  const [textHiding, setTextHiding] = useState<boolean>(false);

  const [renderLoadingText, setRenderLoadingText] = useState<boolean>(false);
  const [hideLoadingText, setHideLoadingText] = useState<boolean>(false);

  useEffect(() => {
    if (
      welcomeText.length - count < 0 &&
      props.animStore.currentState === "ANIM_OUT_TEXT"
    ) {
      props.animStore.setState("START_RENDER_MAIN");
    }

    if (renderLoadingText && count > 100) {
      setCount(0);
      setHideLoadingText(true);
    }

    if (hideLoadingText && count > 100 && renderLoadingText) {
      props.animStore.setState("RENDER_WELCOME_TEXT");
      setCount(0);
    }
  }, [count]);

  useEffect(() => {
    if (props.animStore.currentState === "RENDER_LOADING_TEXT") {
      setRenderLoadingText(true);
      setCount(0);
    }
    if (props.animStore.currentState == "RENDER_WELCOME_TEXT") {
      setRenderLoadingText(false);
    }
  }, [props.animStore.currentState]);

  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = React.useRef<number>();
  const previousTimeRef = React.useRef();

  const animate = (time: any) => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = time - previousTimeRef.current;

      // Pass on a function to the setter of the state
      // to make sure we always have the latest state
      setCount((prevCount) => prevCount + deltaTime * 0.05);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current ?? -1);
  }, []); // Make sure the effect runs only once

  useEffect(() => {
    if (props.animStore.currentState === "ANIM_OUT_TEXT") {
      setTextHiding(true);
      setCount(0);
    }
  }, [props.animStore.currentState]);

  const welcomeText = ">>> press ENTER to begin";
  const loadingText = ">>> loading...";

  return (
    <div
      // className="Enter"
      style={{
        display: "flex",
        height: '100vh',
        flexDirection: "row",
        justifyContent: "center",
        alignItems: 'center',
        color: "green",
      }}
    >
      {renderLoadingText && !hideLoadingText && loadingText.substring(0, count)}
      {renderLoadingText &&
        hideLoadingText &&
        loadingText.substring(0, loadingText.length - count)}
      {!renderLoadingText && !textHiding && welcomeText.substring(0, count)}
      {!renderLoadingText &&
        textHiding &&
        welcomeText.substring(0, welcomeText.length - count)}
      <span
        style={{
          color:
            !renderLoadingText &&
            count > welcomeText.length &&
            Math.floor((count % 100) / 20) % 2 == 0
              ? "green"
              : "black",
        }}
      >
        |
      </span>
    </div>
  );
}

export default WelcomeText;
